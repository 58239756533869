<script lang="ts" setup>
import type { Promotion } from "@/types";

const props = withDefaults(
	defineProps<{
		withTooltip: boolean;
		collapsable?: boolean;
	}>(),
	{
		withTooltip: true,
		collapsable: false
	}
);

const showTooltip = ref(false);
const popperRef = ref(null);

onClickOutside(popperRef, () => {
	showTooltip.value = false;
});

const { t } = useT();
const isGuest = useIsGuest();
const { winnings, entries, tourPoints } = useBalance({
	options: { cached: true, immediate: !isGuest.value, watch: [isGuest] }
});

const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
const { promotionsData, openCash, refresh: refreshPromotionsData } = usePromotionsData({ immediate: false });

const handleSwitchToMode = async () => {
	dispatchGAEvent({
		event: "click_button",
		location: "header",
		button_name: isSweepStakes.value ? "switch_tourney" : "switch_promotional"
	});

	await handleSwitchGameMode();

	if (props.withTooltip) {
		showTooltip.value = true;
		refreshPromotionsData();
	}
};

const isShowWinnings = ref(false);
const toggleWinnings = () => (isShowWinnings.value = !isShowWinnings.value);

const bestDealOffer = computed(() =>
	promotionsData.value?.promotions?.find((offer) => offer?.data?.promoOfferPreset?.bestDeal)
);

const handleOpenCash = () => {
	openCash(bestDealOffer.value as Promotion);
	showTooltip.value = false;
};
</script>
<template>
	<div class="header-balance" data-tid="header-balances">
		<div :class="['balance-wrapper', { collapsable }]" @click="handleSwitchToMode">
			<div class="balance-coins">
				<AText
					as="div"
					type="x-small"
					:modifiers="['bold']"
					class="balance-item"
					:data-tid="`active-${!isSweepStakes}`"
					:class="{ active: !isSweepStakes }"
				>
					<NuxtIcon name="12/coins" filled />
					<AAnimationNumberCounter class="value" :number="tourPoints" data-tid="points-coins" />
				</AText>

				<AText
					as="div"
					type="x-small"
					:modifiers="['bold']"
					class="balance-item"
					:data-tid="`active-${isSweepStakes}`"
					:class="{ active: isSweepStakes }"
				>
					<NuxtIcon name="12/secret-coins" filled />

					<AAnimationNumberCounter class="value" :number="entries" data-tid="entries-coins" />

					<AText as="div" :modifiers="['medium']" class="winnings">
						<i18n-t keypath="Win. {winnings}">
							<template #winnings>
								<AText :modifiers="['normal']" type="x-small" class="value color-neutral-50">
									<AAnimationNumberCounter :number="winnings" :decimals="2" data-tid="winnings-coins" />
								</AText>
							</template>
						</i18n-t>
					</AText>
				</AText>
			</div>

			<div v-if="collapsable" class="toggle-winnings" @click.stop="toggleWinnings">
				<NuxtIcon name="16/arrow-down-small" class="icon-arrow" :class="{ 'winnings-opened': isShowWinnings }" filled />
			</div>
		</div>

		<div id="entries-container" class="balance-toggle" data-tid="open-balances-list">
			<LazyAPopper ref="popperRef" placement="bottom-end" :show="showTooltip" arrow>
				<AToggle :model-value="isSweepStakes" color="var(--neutral)" :size="12" @click="handleSwitchToMode" />
				<template #content>
					<div class="popper-content">
						<OHeaderOrderSummary
							v-if="entries < 100 && isSweepStakes && bestDealOffer"
							:bestDealOffer="bestDealOffer"
							@open-cash="handleOpenCash"
						/>
						<template v-else>
							<div class="popper-icon">
								<ASvg :name="`36/${isSweepStakes ? 'secret-coins' : 'coins'}`" class="icon" />
							</div>
							<AText as="p" type="small" :modifiers="['uppercase', 'bold']">
								{{ isSweepStakes ? t("secret coins mode is on") : t("tao coins mode is on") }}
							</AText>
							<AText type="small" class="color-gray-200">
								{{
									isSweepStakes
										? t("Oh, so you crave deeper knowledge and real prizes!")
										: t("Here you'll enjoy the temple of ultimate gaming experience.")
								}}
							</AText>
						</template>
					</div>
				</template>
			</LazyAPopper>
		</div>
	</div>
</template>
<style scoped lang="scss">
.header-balance {
	display: flex;
	height: 40px;
	border-radius: 4px;
	background-color: var(--gray-700);
	cursor: pointer;
}

.balance-wrapper {
	width: 100%;
	max-width: 195px;
	display: flex;
	justify-content: space-between;
	padding: 2px 24px 2px 12px;
	transition: all 0.2s;
	position: relative;
	cursor: pointer;
	overflow: hidden;

	.balance-coins {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 4px;
	}

	.toggle-winnings {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--neutral-950);
		border-radius: 4px;
		width: 18px;

		.icon-arrow {
			transition: all 0.2s;
			rotate: 90deg;

			&.winnings-opened {
				transform: rotateX(180deg);
			}
		}
	}

	&.collapsable {
		max-width: 115px;
		.winnings {
			opacity: 0;
		}

		&:has(.winnings-opened) {
			max-width: 195px;
			padding-right: 24px !important;
			.winnings {
				opacity: 1;
			}
		}
	}
}

.balance-item {
	display: flex;
	align-items: center;
	gap: 8px;
	opacity: 0.5;
	line-height: 14px;
	color: var(--neutral);

	&:deep(.nuxt-icon svg) {
		margin-bottom: 0;
		vertical-align: top;
	}

	&.active {
		opacity: 1;
	}

	.value {
		display: inline-block;
		min-width: 45px;
	}
}

.winnings {
	font-size: 11px;
	line-height: 14px;
	flex-shrink: 0;
	transition: opacity 0.2;
	opacity: 1;
	color: var(--alternative-green);
}

.balance-toggle {
	position: relative;
	padding: 0 12px;
	&:deep(.inner) {
		width: 16px;
		height: 36px;

		.track {
			opacity: 1;
			background-color: var(--gray-900);
			border: 1px solid var(--alternative-gold);
		}

		&.truthy .track {
			border: 1px solid var(--alternative-green);
		}

		.thumb {
			width: 20px;
			height: 20px;
			top: -1px;
			left: -2px;
			transform: none;

			&::after {
				background: url("~/assets/icons/12/coins.svg") center/cover no-repeat;
			}
		}

		&.truthy .thumb {
			top: calc(100% - 19px);

			&::after {
				background: url("~/assets/icons/12/secret-coins.svg") center/cover no-repeat;
			}
		}
	}

	&:deep(.popper) {
		width: 260px;
		right: -32px !important;

		@media (max-height: 459px) and (orientation: landscape) {
			max-height: 202px;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-thumb {
				background: var(--alternative-green);
				border-radius: 4px;
				width: 4px;
			}
		}

		> #arrow {
			transform: none !important;
			left: auto !important;
			right: 35px !important;
		}
	}

	.popper-content {
		p {
			margin: 0 0 8px;
		}
	}

	.popper-icon {
		display: flex;
		align-items: center;
		margin-bottom: 12px;

		.icon {
			font-size: 36px;
		}
	}
}
</style>
